import React from "react"
import Layout from "@components/layout"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Sticky from "react-sticky-el"
import Seo from "@components/seo"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
} from "react-share"

import shareImage from "../images/pexels-cats-coming-920220.jpg"

// import WebriQForm from "@webriq/gatsby-webriq-form"

import { LazyLoadComponent } from "react-lazy-load-image-component"

// import LinkedInIcon from "@images/linkedin-colored-icon.svg"
// import FacebookIcon from "@images/facebook-colored-icon.svg"
// import TwitterIcon from "@images/twitter-colored-icon.svg"
import HeartIcon from "@images/heart-icon.svg"
// import VideoIcon from "@images/video-icon-sm.svg"

import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

const slugify = require("slugify")

const BlogTemplate = ({ location, data, pageContext }) => {
  const post = data.post
  const posts = data.allSanityPost.edges
  const { previous, next } = pageContext
  // const metaImage = post.mainImage.asset.gatsbyImageData.images.fallback.src
  const blogImage = getSrc(post?.mainImage?.asset?.gatsbyImageData)

  const featuredCat = data.allSanityCategory.nodes

  // console.log("data template", data)
  // const filterTrending = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Trending"
  // )

  // const filterResearch = data.allSanityPost.group.filter(
  //   fltr => fltr.fieldValue === "Research and Thought Provoking"
  // )

  // console.log("Testing rani direng dapita", posts)
  console.log(`mainImage`, blogImage)

  return (
    <Layout location={location}>
      <Seo title={`${post.title} | Fluo Foundation`} />

      <Helmet
        meta={[
          {
            property: `og:image`,
            content: blogImage,
          },

          {
            property: `twitter:image`,
            content: blogImage,
          },
        ]}
      />

      <div style={{ marginTop: "9.5rem" }}>
        {/* <div className="my-5 py-3">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ backgroundColor: "pink" }}
            >
              This area is for the BREADCRUMBS
            </div>
          </div>
        </div> */}
        <LazyLoadComponent>
          <div
            className="position-relative  socials-container"
            style={{ paddingBottom: "10rem" }}
          >
            <div className="container">
              <div className="row justify-content-between responsive-gutter">
                {/* <LazyLoadComponent> */}
                <div className="col-md-7 col-11 overflow-hidden pr-4">
                  <div className="page-content">
                    <div className="blog-item-categories">
                      {post?.categories?.map(cat => (
                        <Link
                          to={`/category/${slugify(cat.title.toLowerCase())}`}
                          className="blog-category-tag"
                        >
                          <span
                            className={`category-color ${(cat.title ===
                              "Business" &&
                              "business-color") ||
                              (cat.title === "Thought Provoking" &&
                                "thought-color") ||
                              (cat.title === "Newsworthy" && "news-color") ||
                              (cat.title === "Education" && "edu-color") ||
                              (cat.title === "Featured" && "feat-color") ||
                              (cat.title === "Founder Perspective" &&
                                "founder-color") ||
                              (cat.title === "Health & Wellness" &&
                                "health-color") ||
                              (cat.title === "Inspiration" && "inspi-color") ||
                              "default-color"}`}
                          />
                          {cat.title}
                        </Link>
                      ))}
                    </div>
                    <h1 className="display-4" style={{ fontWeight: 700 }}>
                      {post.title}
                    </h1>

                    <span className="d-block blog-item-details text-muted small my-3">
                      {post.authors[0] ? (
                        <span>
                          {post.authors.length !== 0 ? "Posted by" : ""}
                          {/* <Link
                            to={`/author/${post.authors[0]?.person?.slug.current}`}
                            className="link-unstyled"
                            style={{ fontWeight: 700 }}
                          >
                            {post.authors[0]?.person?.name}
                          </Link>{" "} */}
                          <Link
                            to={`/author/${post.authors[0]?.person?.slug.current}`}
                            className="link-unstyled me-2"
                            style={{ fontWeight: 700 }}
                          >
                            <span className="ps-2 pe-2">
                              {post.authors[0]?.person?.name}
                            </span>
                            <span>•</span>
                          </Link>
                        </span>
                      ) : (
                        ""
                      )}

                      {post.publishedAt ? (
                        <div className="d-inline-block">
                          <span>{post.publishedAt}</span>
                        </div>
                      ) : null}
                      {post.minutes ? (
                        <span className="ms-2">
                          <span className="me-2">•</span>`{post.minutes} min
                          read`
                        </span>
                      ) : null}
                    </span>
                    {/* <div className="mt-5 d-grid gap-4"> */}
                    <div className="mt-5">
                      {/* <StaticImage
                          src="../images/brooke-cagle--uHVRvDr7pg-unsplash.jpeg"
                          alt="Blog Post Image"
                          placeholder="blurred"
                          layout="constrained"
                        /> */}
                      <div style={{ textAlign: `${post.imgPosition}` }}>
                        <GatsbyImage
                          image={
                            post.mainImage
                              ? post.mainImage.asset.gatsbyImageData
                              : ""
                          }
                          alt={post.title}
                          // className="w-100 h-100"
                          style={{ width: `${post.imgWidth}px` }}
                        />
                      </div>
                      {/* <div
                        className="mt-5"
                        dangerouslySetInnerHTML={{
                          __html: `{markdown($post.body)}`,
                        }}
                      /> */}
                      <div className="mt-5">
                        <ReactMarkdown
                          children={post.body}
                          remarkPlugins={[remarkGfm]}
                        />
                      </div>
                      <div className="row border-top pt-5 mt-5">
                        <div className="blog-nav">
                          <div className="row">
                            <div className="col-lg-6">
                              {previous ? (
                                <div className="text-lg-start">
                                  <Link to={`/${previous.slug.current}`}>
                                    <span
                                      className="font-weight-bold text-uppercase text-muted d-block small d-flex align-items-center mb-3"
                                      style={{
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                      }}
                                    >
                                      <div className="me-3">
                                        <svg
                                          width="40"
                                          height="41"
                                          viewBox="0 0 40 41"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="20"
                                            cy="20.1968"
                                            r="19.5"
                                            transform="rotate(-180 20 20.1968)"
                                            stroke="#737373"
                                          />
                                          <path
                                            d="M22 14.1968L16 20.1968L22 26.1968"
                                            stroke="#737373"
                                            stroke-width="3"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </div>
                                      Previous
                                    </span>
                                    <span
                                      className="blog-item-title"
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "1.25rem",
                                        color: "#212529",
                                      }}
                                    >
                                      {previous.title}
                                    </span>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-lg-6">
                              {next ? (
                                <div className="text-lg-end">
                                  <Link to={`/${next.slug.current}`}>
                                    <span
                                      className="font-weight-bold text-uppercase text-muted d-block small d-flex align-items-center justify-content-end mb-3"
                                      style={{
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                      }}
                                    >
                                      next
                                      <div className="ms-3">
                                        <svg
                                          width="40"
                                          height="41"
                                          viewBox="0 0 40 41"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="20"
                                            cy="20.1968"
                                            r="19.5"
                                            stroke="#737373"
                                          />
                                          <path
                                            d="M18 26.1968L24 20.1968L18 14.1968"
                                            stroke="#737373"
                                            stroke-width="3"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </div>
                                    </span>
                                    <span
                                      className="blog-item-title"
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "1.25rem",
                                        color: "#212529",
                                      }}
                                    >
                                      {next.title}
                                    </span>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-1">
                  {" "}
                  <div className="position-relative h-100">
                    <div className="pr-2">
                      <Sticky
                        className="sticky-wrapper"
                        stickyClassName="is-sticky"
                        stickyStyle={{
                          transform: "unset",
                          zIndex: 1,
                        }}
                        boundaryElement=".socials-container"
                        hideOnBoundaryHit={false}
                        topOffset={50}
                      >
                        <div className="d-flex justify-content-center">
                          <ul className="list-inline m-0 blog-socials d-grid">
                            {/* <li className="list-inline-item ">
                        <EmailShareButton
                          url={`https://www.fluofoundation.org/${slugify(
                            post.title.toLowerCase(),
                            { remove: /'/ }
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <EmailIcon size={25} borderRadius={100} />
                        </EmailShareButton>
                      </li> */}
                            <li className="list-inline-item mt-2">
                              <FacebookShareButton
                                url={`https://www.fluofoundation.org/${slugify(
                                  post.title.toLowerCase(),
                                  { remove: /'/ }
                                )}`}
                              >
                                <FacebookIcon size={30} borderRadius={100} />
                              </FacebookShareButton>
                            </li>
                            <li className="list-inline-item mt-2">
                              <TwitterShareButton
                                url={`https://www.fluofoundation.org/${slugify(
                                  post.title.toLowerCase(),
                                  { remove: /'/ }
                                )}`}
                              >
                                <TwitterIcon size={30} borderRadius={100} />
                              </TwitterShareButton>
                            </li>
                            <li className="list-inline-item mt-2">
                              <LinkedinShareButton
                                url={`https://www.fluofoundation.org/${slugify(
                                  post.title.toLowerCase(),
                                  { remove: /'/ }
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LinkedinIcon size={30} borderRadius={100} />
                              </LinkedinShareButton>
                            </li>
                            <li className="list-inline-item mt-2">
                              <PinterestShareButton
                                // url={`https://www.fluofoundation.org/${slugify(
                                //   post.title.toLowerCase(),
                                //   { remove: /'/ }
                                // )}`}
                                // target="_blank"
                                // rel="noreferrer"
                                url={location}
                                windowWidth={1000}
                                windowHeight={730}
                                media={blogImage}
                                description={post.title}
                              >
                                <PinterestIcon size={30} borderRadius={100} />
                              </PinterestShareButton>
                            </li>
                          </ul>
                        </div>
                      </Sticky>
                    </div>
                  </div>
                </div>

                {/* </LazyLoadComponent> */}
                <div className="col-md-4 col-lg-4 sidebar-column">
                  {post.authors[0] ? (
                    <LazyLoadComponent>
                      <div className="pt-mobile-spacing">
                        <div className="py-5 px-4 bg-light rounded-25">
                          <div className="row align-items-center justify-content-center position-relative">
                            <Link
                              className="position-absolute w-100 h-100"
                              style={{ top: 0, right: 0, zIndex: 6 }}
                              to={`/author/${post.authors[0]?.person?.slug.current}`}
                            />
                            <div className="col-12 col-lg-5 text-center mb-3 mb-lg-0 d-flex">
                              {/* <StaticImage
                              src="../images/author-avatar.png"
                              alt="Blog Post Image"
                              placeholder="blurred"
                              layout="constrained"
                            /> */}
                              <GatsbyImage
                                image={
                                  post.authors[0]?.person?.image?.asset
                                    .gatsbyImageData
                                }
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  width: "120px",
                                  height: "120px",
                                  borderRadius: "50%",
                                }}
                                className="mx-auto"
                              />
                              <div
                                style={{
                                  gridArea: "1/1",
                                  placeItems: "center",
                                }}
                              />
                            </div>
                            <div className="col-12 col-lg-7 text-center text-lg-start">
                              <h2 className=" m-0" style={{ fontWeight: 700 }}>
                                {post.authors[0]?.person?.name}
                              </h2>
                              <span
                                className="text-muted text-uppercase small"
                                style={{ fontWeight: 600 }}
                              >
                                About the Author
                              </span>
                            </div>
                          </div>
                          <p className="my-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.authors[0]?.person?.bio[0]?.children[0]
                                    .text,
                              }}
                            />
                          </p>
                          <div className="row justify-content-between align-items-center">
                            <div className="col-sm-6 col-md-12 col-lg-6 text-center text-sm-start text-md-center text-lg-start mb-3 mb-lg-0">
                              <Link
                                to={`/author/${post.authors[0]?.person?.slug.current}`}
                                className="btn btn-primary btn-white btn-sm"
                                role="button"
                              >
                                View Posts
                              </Link>
                            </div>
                            {/* <div className="col-sm-6 col-md-12 col-lg-6 text-center text-sm-end text-md-center text-lg-end">
                              <div className="socials-inline">
                                <a
                                  href="/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ marginRight: "0.5rem" }}
                                >
                                  <img src={FacebookIcon} alt="Facebook" />
                                </a>
                                <a
                                  href="/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ marginRight: "0.5rem" }}
                                >
                                  <img src={TwitterIcon} alt="Twitter" />
                                </a>
                                <a href="/" target="_blank" rel="noreferrer">
                                  <img src={LinkedInIcon} alt="LinkedIn" />
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </LazyLoadComponent>
                  ) : (
                    ""
                  )}
                  <LazyLoadComponent>
                    <div
                      // className="d-grid gap-5 pt-spacing"
                      className={`d-grid gap-5 ${
                        post.authors[0] ? "pt-spacing" : ""
                      }`}
                    >
                      <h2
                        className="display-6 bordered-heading"
                        style={{ fontWeight: 700 }}
                      >
                        Latest posts
                      </h2>
                      {posts.slice(0, 2).map(allPosts => (
                        <div className="blog-item">
                          <div className="blog-text">
                            <div className="blog-item-categories">
                              {allPosts?.node?.categories?.map(cat => (
                                <Link
                                  to={`/category/${slugify(
                                    cat.title.toLowerCase()
                                  )}`}
                                  className="blog-category-tag"
                                >
                                  <span
                                    className={`category-color ${(cat.title ===
                                      "Business" &&
                                      "business-color") ||
                                      (cat.title === "Thought Provoking" &&
                                        "thought-color") ||
                                      (cat.title === "Newsworthy" &&
                                        "news-color") ||
                                      (cat.title === "Education" &&
                                        "edu-color") ||
                                      (cat.title === "Featured" &&
                                        "feat-color") ||
                                      (cat.title === "Founder Perspective" &&
                                        "founder-color") ||
                                      (cat.title === "Health & Wellness" &&
                                        "health-color") ||
                                      (cat.title === "Inspiration" &&
                                        "inspi-color") ||
                                      "default-color"}`}
                                  />
                                  {cat.title}
                                </Link>
                              ))}
                            </div>
                            <Link
                              to={`/${allPosts?.node?.slug?.current}`}
                              className="link-unstyled"
                            >
                              <h3
                                className="h4 mt-2 mb-3 blog-item-title"
                                style={{ fontWeight: 600 }}
                              >
                                {allPosts.node.title}
                              </h3>
                            </Link>
                            <span className="d-block small blog-item-details">
                              {allPosts.node.authors.length !== 0
                                ? "Posted by"
                                : ""}
                              <Link
                                to="/"
                                className="link-unstyled"
                                style={{ fontWeight: 700 }}
                              >
                                {allPosts.node.authors.map(author => (
                                  <Link
                                    to={`/author/${author?.person?.slug.current}`}
                                    className="link-unstyled me-2"
                                    style={{ fontWeight: 700 }}
                                  >
                                    <span className="ps-2 pe-2">
                                      {author?.person?.name}
                                    </span>
                                    <span>•</span>
                                  </Link>
                                ))}
                              </Link>
                              {allPosts.node.publishedAt ? (
                                <div className="d-inline-block">
                                  <span>{allPosts.node.publishedAt}</span>
                                </div>
                              ) : null}
                              {allPosts.node.minutes ? (
                                <span className="ms-2">
                                  <span className="me-2">•</span>
                                  {allPosts.node.minutes} min read
                                </span>
                              ) : null}
                              {/* {allPosts.node.publishedAt} • 5 min read */}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </LazyLoadComponent>
                  <LazyLoadComponent>
                    <div className="pt-spacing">
                      <div className="px-4 py-5 bg-dark-blue text-white text-center rounded-25">
                        <h2 className="h5" style={{ fontWeight: 700 }}>
                          {/* Subscribe to Our Newsletter */}
                          Stay Connected!
                        </h2>
                        <h5 style={{ fontSize: "0.75rem", fontWeight: 700 }}>
                          Let's Rally Behind our Youth!
                        </h5>                        
                        <iframe
                          title="Subscribe"
                          scrolling="no"
                          src="https://www.forms.fluofoundation.org/szajtg"
                          style={{
                            border: "0px;",
                            width: "100%",
                            height: "120px",
                          }}
                        ></iframe>
                      </div>
                    </div>
                  </LazyLoadComponent>
                  <LazyLoadComponent>
                    <div className="pt-spacing">
                      <div className="cta-box donation-cta text-center text-white bg-dark rounded-25">
                        <StaticImage
                          style={{
                            gridArea: "1/1",
                            borderRadius: 25,
                            zIndex: 1,
                            content: "",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          layout="fullWidth"
                          placeholder="blurred"
                          alt="Category Banner"
                          src={"../images/donation-cta-bg.png"}
                          formats={["auto", "webp", "avif"]}
                        />
                        <div
                          className="content-wrapper"
                          style={{ padding: "1rem" }}
                        >
                          <h2 className="font-weight-bold">Make a Donation</h2>
                          {/* <p className="mt-4 mb-4">
                            While 1 in 5 people will experience a mental illness
                            during their lifetime, everyone faces challenges in
                            life that can impact their mental health. Join us in
                            supporting the effort in promoting mental health for
                            all!
                          </p> */}
                          <p className="mt-4 mb-4">
                            Driven by a bold vision for the profound
                            transformation in the mental health of young
                            individuals, the FLUO Foundation relies on the
                            charitable donations of those who are ready to rally
                            behind our youth.
                          </p>
                          <Link
                            className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                            role="button"
                            href="/donate.html"
                            style={{ width: 186, height: 54, fontWeight: 600 }}
                          >
                            Donate
                            <img
                              src={HeartIcon}
                              alt="Heart Icon"
                              className="ms-2"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </LazyLoadComponent>
                  {/* <LazyLoadComponent>
                    <div className="d-grid gap-5 pt-spacing">
                      <h2 className="display-6 font-weight-bold bordered-heading">
                        Trending
                      </h2>
                      {filterTrending[0].edges.slice(0, 2).map(trend => (
                        <div className="blog-item">
                          <Link to={`/${trend.node.slug.current}`}>
                            <div
                              className="
                      blog-image-wrapper
                      image-wrapper-rectangle
                      mb-3
                      rounded-25
                    "
                            >
                              <GatsbyImage
                                image={
                                  trend.node.mainImage
                                    ? trend.node.mainImage.asset.gatsbyImageData
                                    : ""
                                }
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: 25,
                                }}
                              />
                              <div
                                style={{
                                  gridArea: "1/1",
                                  placeItems: "center",
                                }}
                              />
                              <div className="blog-item-type" title="video">
                                <img src={VideoIcon} alt="Video icon" />
                              </div>
                            </div>
                          </Link>
                          <div className="blog-text">
                            <div className="blog-item-categories">
                              {trend.node.categories.map(cat => (
                                <Link
                                  to={`/category/${slugify(
                                    cat.title.toLowerCase()
                                  )}`}
                                  className="blog-category-tag"
                                >
                                  <span
                                    className={`category-color ${(cat.title ===
                                      "Business" &&
                                      "business-color") ||
                                      (cat.title === "Creative" &&
                                        "creative-color") ||
                                      (cat.title === "News" && "news-color") ||
                                      (cat.title === "Strategy" &&
                                        "strategy-color") ||
                                      "default-color"}`}
                                  />
                                  {cat.title}
                                </Link>
                              ))}
                            </div>
                            <Link
                              to={`/${trend.node.slug.current}`}
                              className="link-unstyled"
                            >
                              <h3
                                className="h4 mt-2 mb-3 blog-item-title"
                                style={{ fontWeight: "600" }}
                              >
                                {trend.node.title}
                              </h3>{" "}
                            </Link>
                            <span className="d-block small blog-item-details">
                              Posted by&nbsp;
                              {trend.node.authors.map(author => (
                                <Link
                                  to={`/author/${author?.person?.slug.current}`}
                                  className="link-unstyled"
                                  style={{ fontWeight: 700 }}
                                >
                                  {author?.person?.name}
                                </Link>
                              ))}
                              • {trend.node.publishedAt} • 5 min read
                            </span>
                            <p className="my-3">{trend.node.excerpt}</p>
                            <Link
                              to={`/${trend.node.slug.current}`}
                              className="btn btn-primary"
                              role="button"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </LazyLoadComponent> */}
                  <LazyLoadComponent>
                    <div className="d-grid gap-5 pt-spacing">
                      <h2
                        className="display-6 bordered-heading mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4"
                        style={{ fontWeight: "bold" }}
                      >
                        Categories
                      </h2>
                      <div className="d-grid gap-3">
                        {featuredCat.map(featCat => (
                          <Link
                            to={`/category/${slugify(
                              featCat.title.toLowerCase()
                            )}`}
                            className="category-banner-link link-unstyled"
                          >
                            {(featCat.title === "Thought Provoking" && (
                              <span className="thought-color">
                                <StaticImage
                                  style={{
                                    gridArea: "1/1",
                                    zIndex: 1,
                                    borderRadius: 10,
                                    content: "",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  layout="fullWidth"
                                  placeholder="blurred"
                                  // aspectRatio={3 / 1}
                                  alt="Category Banner"
                                  src={"../images/pexels-burst-374720.jpg"}
                                  formats={["auto", "webp", "avif"]}
                                />
                              </span>
                            )) ||
                              (featCat.title === "Founder Perspective" && (
                                <span className="founder-color">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/founder.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Newsworthy" && (
                                <span className="news-cat">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={
                                      "../images/pexels-daria-shevtsova-1928151.jpeg"
                                    }
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Education" && (
                                <span className="edu-color">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/educ.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Inspiration" && (
                                <span className="inspi-color">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/inspiration.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Health & Wellness" && (
                                <span className="health-color">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/health.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Featured" && (
                                <span className="feat-color">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/featured.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title === "Newsworthy" && (
                                <span className="news-cat">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={
                                      "../images/pexels-daria-shevtsova-1928151.jpeg"
                                    }
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              )) ||
                              (featCat.title && (
                                <span className="business-cat">
                                  <StaticImage
                                    style={{
                                      gridArea: "1/1",
                                      zIndex: 1,
                                      borderRadius: 10,
                                      content: "",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    placeholder="blurred"
                                    // aspectRatio={3 / 1}
                                    alt="Category Banner"
                                    src={"../images/pexels-burst-374720.jpg"}
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </span>
                              ))}
                            <div
                              style={{
                                gridArea: "1/1",
                                placeItems: "center",
                              }}
                            />
                            <div className="blog-category-tag">
                              <Link
                                to={`/category/${slugify(
                                  featCat.title.toLowerCase()
                                )}`}
                              >
                                <span
                                  className={`category-color ${(featCat.title ===
                                    "Business" &&
                                    "business-color") ||
                                    (featCat.title === "Thought Provoking" &&
                                      "thought-color") ||
                                    (featCat.title === "Newsworthy" &&
                                      "news-color") ||
                                    (featCat.title === "Education" &&
                                      "edu-color") ||
                                    (featCat.title === "Featured" &&
                                      "feat-color") ||
                                    (featCat.title === "Founder Perspective" &&
                                      "founder-color") ||
                                    (featCat.title === "Health & Wellness" &&
                                      "health-color") ||
                                    (featCat.title === "Inspiration" &&
                                      "inspi-color") ||
                                    "default-color"}`}
                                />
                                {featCat.title}
                              </Link>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </LazyLoadComponent>
                </div>
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      mainImage {
        asset {
          gatsbyImageData
        }
      }

      imgPosition
      imgWidth
      authors {
        person {
          name
          image {
            asset {
              gatsbyImageData
            }
          }
          bio {
            children {
              text
            }
          }
          slug {
            current
          }
        }
      }
      categories {
        title
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      minutes
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            excerpt
            publishedAt(formatString: "MMMM DD, YYYY")
            minutes
            categories {
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
          }
        }
      }
      edges {
        node {
          id
          slug {
            current
          }
          minutes
          authors {
            person {
              name
              image {
                asset {
                  gatsbyImageData
                }
              }
              slug {
                current
              }
            }
          }
          categories {
            featuredCategories
            title
          }
          excerpt
          body
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData
              title
            }
          }
        }
      }
    }
    allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        featuredCategories
        title
      }
    }
  }
`
